import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.json.stringify.js";
/************************************************* 
 THIS IS THE TEMPLATE FILE FOR THE  "BEREICHT" 

 *************************************************/

var d = new Date();
function fixZeros(val) {
  // ADD UP '0' TO MONTHS AND DAYS IF < 10
  val = "" + val;
  if (val.length == 1) {
    val = "0" + val;
  }
  return val;
}
var Today = d.getFullYear() + "-" + fixZeros(d.getMonth() + 1) + "-" + fixZeros(d.getDate());
function MakeBool(ka, nb) {
  var obj = [];
  if (ka) obj.push({
    name: 'KEINE ANGABE'
  });
  if (nb) obj.push({
    name: 'NICHT BEKANNT'
  });
  obj.push({
    name: "JA"
  });
  obj.push({
    name: "NEIN"
  });
  return obj;
}
function MakeArray(from, to, dir, ka, nb) {
  var obj = [];
  // obj.push('KEINE ANGABE')
  if (ka) obj.push({
    name: 'KEINE ANGABE'
  });
  if (nb) obj.push({
    name: 'NICHT BEKANNT'
  });
  if (dir > 0) for (var i = from; i <= to; i++) {
    obj.push({
      name: i + ""
    });
  } else if (dir < 0) for (var _i = to; _i >= from; _i--) {
    obj.push({
      name: _i + ""
    });
  }
  return obj;
}
function MakeArray_NO(from, to, dir) {
  var arr = [];
  // arr.push('KEINE ANGABE')
  if (dir > 0) for (var i = from; i <= to; i++) {
    arr.push(i + "");
  } else if (dir < 0) for (var _i2 = to; _i2 >= from; _i2--) {
    arr.push(_i2 + "");
  }
  return arr;
}
function objectivy(vals, ka, nb) {
  var obj = [];
  if (ka) obj.push({
    name: 'KEINE ANGABE'
  });
  if (nb) obj.push({
    name: 'NICHT BEKANNT'
  });
  vals.forEach(function (element) {
    obj.push({
      name: element
    });
  });
  return JSON.parse(JSON.stringify(obj));
}

/************************************************* 
 BUILDING TEMPLATE 
 *************************************************/
var buildingtemplate = [{
  // IMPORTANT: KEEP THIS IN FIRST PLACE OF ARRAY
  type: "binfo",
  // blocktitle: "Gebäudeobjekt",
  // title: "binfo",
  title: "Gebäudeobjekt",
  values: [{
    name: "Bezeichnung",
    value: "Gebäudeobjekt 1",
    type: "text"
  }]
}, {
  type: "eckdaten",
  // blocktitle: "Gebäudeobjekt",
  title: "Eckdaten",
  values: [{
    name: "Gebäudeart",
    value: "",
    type: "select",
    vals: "gebaeudeart",
    hvw: "objektart"
  }, {
    name: "Geschosse unter Erdniveau",
    value: 1,
    type: "number"
  }, {
    name: "Geschosse über Erdniveau",
    value: 2,
    type: "number"
  }]
}, {
  type: "nutzung",
  title: "Nutzung",
  _meta: {
    vals: objectivy(["geschosse", "geschosse_dach"])
  },
  values: [{
    name: "Kellergeschoss",
    type: "select",
    value: [],
    vals: "geschosse"
  }, {
    name: "Erdgeschoss",
    required: true,
    type: "select",
    value: [],
    vals: "geschosse"
  }, {
    name: "Obergeschoss",
    type: "select",
    value: [],
    vals: "geschosse"
  }, {
    name: "Dach",
    type: "select",
    value: [],
    vals: "geschosse_dach"
  }]
}, {
  type: "list",
  title: "Außenanlagen",
  values: [{
    name: "Beschreibung",
    value: [],
    type: "select",
    vals: "aussen",
    hvw: "aussenanlagen"
  }]
}, {
  type: "list",
  title: "Bauteile - Substanzbeschreibung",
  values: [{
    name: "Bauweise",
    value: [],
    type: "select",
    vals: "bauweise"
  }, {
    name: "Fassaden",
    value: [],
    type: "select",
    vals: "fassaden"
  }, {
    name: "Decken",
    value: [],
    type: "select",
    vals: "decken"
  }, {
    name: "Dachung/Dachdeckung",
    value: [],
    type: "select",
    vals: "dachung"
  }, {
    name: "Stiegenanlagen",
    value: [],
    type: "select",
    vals: "stiegenanlagen"
  }, {
    name: "Bodenbelag",
    value: [],
    type: "select",
    vals: "bodenbelag"
  }, {
    name: "Wandbelag",
    value: [],
    type: "select",
    vals: "wandbelag"
  }, {
    name: "Deckenbelag",
    value: [],
    type: "select",
    vals: "deckenbelag"
  }, {
    name: "Fenster/Lichteinlass",
    value: [],
    type: "select",
    vals: "fenster"
  }, {
    name: "Tore/Türen",
    value: [],
    type: "select",
    vals: "tore"
  }, {
    name: "Heizungsanlage",
    value: [],
    type: "select",
    vals: "heizungsanlage",
    hvw: "heizungsanlage"
  }, {
    name: "zusätzliche Haustechnik",
    value: [],
    type: "select",
    vals: "haustechnik",
    hvw: "haustechnik"
  }]
}, {
  type: "floors",
  // 5
  title: "Geschosse",
  floors: [{
    name: "2. Untergeschoss",
    area: null,
    height: null,
    vol: null,
    ehw: null,
    cost: null
  }, {
    name: "1. Untergeschoss",
    area: null,
    height: null,
    vol: null,
    ehw: null,
    cost: null
  }, {
    name: "Erdgeschoß",
    area: null,
    height: null,
    vol: null,
    ehw: null,
    cost: null
  }, {
    name: "1. Obergeschoss",
    area: null,
    height: null,
    vol: null,
    ehw: null,
    cost: null
  }, {
    name: "2. Obergeschoss",
    area: null,
    height: null,
    vol: null,
    ehw: null,
    cost: null
  }],
  allFloorsData: {
    area: 0,
    vol: 0,
    cost: 0
  }
},
// NOT USED IN BERICHT:
// sonstiges / dachbodenausbau

{
  type: "text",
  title: "Sonstiges",
  values: [{
    name: "Sonstige Information",
    value: ""
    // type: "select"
    // vals: "aussen"
  }]
}];

/************************************************* 
 MAIN TEMPLATE: 
 *************************************************/

var doctemplate = {
  vals: {
    betriebsart: objectivy(["Büro", "Gewerbe", "Praxis", "Schule/Kindergarten"], true),
    baujahr: MakeArray(1850, 2020, -1, true, true),
    renovierung: MakeArray(1940, 2020, -1, true, true),
    wohneinheiten: MakeArray(0, 3000, 1, true, true),
    dachbodenausbau: MakeArray(1940, 2020, -1, true, true),
    // ONLY HVW MAYBE NOT NEEDED
    percent: MakeArray(0, 100, 1, true, true),
    gebaeudeart: objectivy(["NICHT BEKANNT", "Wohnhausanlage", "Wohngebäude WEG", "Wiener Wohnhaus (Mietzinshaus)", "Einfamilienwohnhaus", "Mehrfamilienwohnhaus", "Wohn- und Geschäftsgebäude", "Reihenhausanlage"], true),
    yesno: MakeBool(true, true),
    geschosse: objectivy(["Tiefgarage", "Kellerabteile", "Technikräume", "Schleuse", "Stiegenhaus", "Gang", "Foyer", "Vestibül", "Geschäfte", "Büro(s)", "Lagerräume", "Wohnungen", "Kinderwagen / Fahrradabstellraum", "Müllraum", "Gewerbelokal (Gastro)", "nicht ausgebaut"]),
    geschosse_dach: objectivy(["NICHT BEKANNT", "Pultdach", "Flachdach", "Schrägdach"]),
    geschosse_art: objectivy(["NICHT BEKANNT", "hart", "weich"]),
    aussen: objectivy(["NICHT BEKANNT", "befestigte Fläche", "Müllplatz", "Fahrradabstellplatz", "Flugdach", "Carport", "Park", "Parkplatz", "Einfriedung"]),
    bauweise: objectivy(["NICHT BEKANNT", "Stahlbetonbauweise", "Holzbauweise", "Stahlbauweise", "Ziegelbauweise", "Fertigteilbauweise"]),
    fassaden: objectivy(["NICHT BEKANNT", "Vollwärmeschutzfassade", "reichlich gegliederte Fassade", "gegliederte Fassade", "Fassadenputz gerieben und gefärbelt", "Fassadenplatten (WGD)", "Fertigteil", "Blech/Metall", "Glas/Alu"]),
    decken: objectivy(["NICHT BEKANNT", "Ziegelgewölbe", "Tramdecken", "Dippelbaumdecke", "Stahlbetondecke", "Fertigteildecken", "Platzldecke", "Tram-Traversendecke"]),
    dachung: objectivy(["NICHT BEKANNT", "harte Deckung", "weiche Deckung"]),
    stiegenanlagen: objectivy(["NICHT BEKANNT", "Stahlbetontreppe",
    // "Natursteintreppe aus Karstmarmor mit schmiedeeisernem Geländer",
    "Steintreppe mit schmiedeeisernem Geländer", "Geländer", "Handlauf", "Holztreppe", "Mansardentreppe", "Stahltreppe"]),
    bodenbelag: objectivy(["NICHT BEKANNT", "keram. Belag", "Naturstein", "Terrazzo", "Parkett/Laminat", "Teppich", "Feinasphalt", "PVC/Linol", "Kunststeinboden", "diverse Bodenbeläge (Wohnungen)"]),
    wandbelag: objectivy(["NICHT BEKANNT", "Anstrich/Malerei/Tapeten", "keram. Belag", "Paneele", "Gipskarton", "Stuck"]),
    deckenbelag: objectivy(["NICHT BEKANNT", "Anstrich/Malerei/Tapeten", "abgehängte Decken", "Stuckdecken"]),
    fenster: objectivy(["NICHT BEKANNT", "Holzkastenfenster", "Holz", "Kunststoff", "Alu-Kunststoff", "Holzfenster mit Isolierverglasung", "Verbundfenster", "Lichtkuppeln", "Dachflächenfenster", "Sonnenschutzverglasung", "Brandrauchentlüftungen"]),
    tore: objectivy(["NICHT BEKANNT", "Ein- und zweiflügelige Türen", "Brandschutztüren", "Wohnungseingangstüren", "Türblätter (kombinierten Werkstoffe) in Stahlzargen", "Türen mit kombinierten Werkstoffen", "elektr. Glasschiebetüren", "Nurglastüren", "Holzflügeltüren in Futterstöcken", "Holzmassivtüren mit Glaseinlagen & schmiedeeisernem Gitter", "Sicherheitstüren", "elektr. Sektionaltor", "Kipptor", "Geschäftsportale"]),
    heizungsanlage: objectivy(["NICHT BEKANNT", "vorhanden", "Fernwärmeheizung", "Gasheizungsanlage", "Festbrennstoffheizungsanlage", "Gasetagenheizung", "Ölheizungsanlage", "Elektroheizung", "Tankraum", "Wärmepumpe(n)", "Infrarotheizung"]),
    haustechnik: objectivy(["NICHT BEKANNT", "Personenaufzug", "Rolltreppe", "Photovoltaik Anlagen", "Solaranlagen", "zentrale Klimaanlage", "Klimasplitgeräte", "Lüftungsanlage", "Beschattungsanlagen (elektr. oder mechanisch)", "Wasseraufbereitungsanlage", "CO-Warnanlage"])
  },
  /************************************************* 
   resetBericht 
   *************************************************/
  resetBericht: [{
    type: "head",
    title: "Gebäudebewertung",
    values: [
    // LEFT SIDE:
    {
      name: "Auftraggeber",
      value: "--",
      type: "textarea",
      // large
      pos: "left",
      hvw: "vnaddress"
    }, {
      name: "Baujahr (ca.)",
      value: "KEINE ANGABE",
      type: "select",
      vals: "baujahr",
      custom: {
        prefix: true,
        prefix_val: "ca. "
      },
      pos: "left",
      hvw: "baujahr"
    }, {
      name: "Wohneinheiten (ca.)",
      value: "KEINE ANGABE",
      type: "select",
      vals: "wohneinheiten",
      pos: "left",
      hvw: "wohneinheiten"
    }, {
      name: "Letzte Renovierung (ca.)",
      value: "KEINE ANGABE",
      type: "select",
      vals: "renovierung",
      pos: "left",
      hvw: "renovierung"
    }, {
      name: "Begehung",
      value: Today,
      type: "date",
      // overtake: "svData.besichtigungsDatum",
      pos: "left"
    },
    // RIGHT SIDE:
    {
      name: "VN",
      value: "KEINE ANGABE",
      type: "text",
      pos: "right",
      hvw: "vnaddressname"
    }, {
      name: "Risikoadresse",
      value: "KEINE ANGABE",
      type: "textarea",
      // large
      pos: "right",
      hvw: "address"
    }, {
      name: "Pol.Nr",
      value: "KEINE ANGABE",
      type: "text",
      pos: "right",
      hvw: "polnr"
    }, {
      name: "Gewerbliche Nutzung",
      value: "KEINE ANGABE",
      type: "booltext",
      vals: "yesno",
      name2: "Anteil der Fläche in %",
      value2: "KEINE ANGABE",
      suffix2: "%",
      type2: "select",
      vals2: "percent",
      name3: "Betriebsart",
      value3: ["KEINE ANGABE"],
      type3: "select",
      vals3: "betriebsart",
      pos: "right",
      hvw: "gewerbe"
    }, {
      name: "Denkmalschutz",
      value: "KEINE ANGABE",
      type: "select",
      vals: "yesno",
      pos: "right",
      hvw: "denkmal"
    }, {
      name: "Stichtag",
      value: Today,
      type: "date",
      pos: "right"
    }]
  }, {
    type: "headimage",
    title: "Titel Bild",
    values: [{
      url: "https://api.consense.at/free/preview_original.png",
      caption: "Gebäudeansicht allgemein"
    }]
  }, {
    type: "headcalculation",
    title: "Summen",
    values: [{}]
  }, {
    type: "mapimage",
    title: "Karten Bild",
    values: [{
      url: "https://api.consense.at/free/preview_original.png",
      caption: "Lage der Liegenschaft"
    }
    // {
    //   url: "https://api.consense.at/free/preview_original.png",
    //   caption: "Lage der Liegenschaft"
    // }
    ]
  }, {
    type: "buildings",
    title: "Objekte",
    values: [],
    buildings: [buildingtemplate]
  },
  /************************************************* 
   EXCEL 
   *************************************************/
  {
    type: "excel",
    wnfl: false,
    title: "Berechnung",
    table: {
      misc: [{
        name: "Außenanlagen",
        cost: 0
      }],
      summisc: 0,
      total: {
        name: "Gesamt",
        cost: 0
      },
      allFloorsData: []
    }
  }],
  AllTypes: [{
    type: "head",
    blocktitle: "Gebäudebewertung",
    template: [{
      name: "Auftraggeber",
      value: "---"
    }, {
      name: "Baujahr",
      value: "---"
    }, {
      name: "Wohneinheiten",
      value: "---"
    }, {
      name: "Letzte Renovierung",
      value: "---"
    }, {
      name: "Zustand",
      value: "---"
    }, {
      name: "VN",
      value: "---"
    }, {
      name: "Pol.Nr",
      value: "---"
    }, {
      name: "Risikoadresse",
      value: "---"
    }, {
      name: "Kontaktperson",
      value: "---"
    }, {
      name: "Gewerbliche Nutzung",
      value: "---"
    }, {
      name: "Betriebsart",
      value: "---"
    }, {
      name: "Denkmalschutz",
      value: "---"
    }, {
      name: "Begehung",
      value: "---"
    }]
  }, {
    type: "list",
    blocktitle: "Daten",
    template: [{
      title: "Listentitel",
      values: [{
        name: "-",
        value: "-"
      }]
    }]
  }, {
    type: "image",
    blocktitle: "Abbildungen",
    template: [{
      url: "https://api.consense.at/free/preview_original.png",
      caption: "Abbildung"
    }]
  }],
  /************************************************* 
   PRE SET TEMPLATE FOR HVW
   *************************************************/
  hvwtemp: {
    address: {
      correctaddress: "KEINE ANGABE",
      city: "-",
      land: "-",
      plz: "-",
      street: "-",
      changed: {
        city: "-",
        land: "-",
        plz: "-",
        street: "-"
      }
    },
    vnaddress: {
      correctaddress: "KEINE ANGABE",
      name: "-",
      city: "-",
      land: "-",
      plz: "-",
      street: "-",
      changed: {
        name: "-",
        city: "-",
        land: "-",
        plz: "-",
        street: "-"
      }
    },
    aussenanlagen: [],
    sonstiges: "",
    denkmal: "KEINE ANGABE",
    gewerbe: {
      isgewerbe: "KEINE ANGABE",
      area: "KEINE ANGABE",
      nutzungsart: "KEINE ANGABE"
    },
    wohneinheiten: "KEINE ANGABE",
    renovierung: "KEINE ANGABE",
    zentral: {
      iskey: "KEINE ANGABE",
      alternative: ""
    },
    baujahr: "KEINE ANGABE",
    objektart: "KEINE ANGABE",
    heizungsanlage: [],
    haustechnik: [],
    dachbodenausbau: {
      is: "KEINE ANGABE",
      when: "KEINE ANGABE"
    }
  }
};
doctemplate.buildingtemplate = buildingtemplate;
export default doctemplate;